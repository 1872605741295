
import React, { useState, useEffect } from 'react';
import { FaLocationDot } from 'react-icons/fa6';
import { IoMdCall } from 'react-icons/io';
import { FaSquareCheck } from 'react-icons/fa6';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import { FiChevronLeft } from 'react-icons/fi';
import { IoVideocam } from 'react-icons/io5';
import { BiSolidReport } from 'react-icons/bi';
import { FaFilePrescription } from 'react-icons/fa';
import { GrNotes } from 'react-icons/gr';
import { RiChat1Line } from 'react-icons/ri';
import MedicalRecord from '../MedicalRecords/MedicalRecord';
import { Person } from 'react-bootstrap-icons';
import { PiStethoscopeFill } from 'react-icons/pi';
import { ConsultationbyID } from '../../apis';
import toast from 'react-hot-toast';
import { MdWorkspacePremium } from "react-icons/md";
import { IoMdCheckmarkCircle } from 'react-icons/io';
import AttendingDoctor from './AttendingDoctor';
import { IoAlertCircle } from 'react-icons/io5';
import Avatar from 'react-avatar';
import MapComponent from '../MapComponent/index';
import { PiGenderMaleBold } from 'react-icons/pi';
import { PiGenderFemaleBold } from 'react-icons/pi';

function ViewDetails({ currentdata, closeFunction ,disputeDetails}) {
  const [data, setdata] = useState(null);
  console.log(currentdata);
  const getconsultaitonbyid = async () => {
    try {
      // 6656d58eafb8539e04508a19
      // 664ee4d9283a65c19903387e
      const res = await ConsultationbyID(currentdata);
      if (res.status === 200) {
        console.log(res.data.message);
        console.log(res.data);
        setdata(res.data?.consultationdata);
      }
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.statusText;
      toast.error(message, { id: 'error' });
    } finally {
    }
  };

  useEffect(() => {
    getconsultaitonbyid();
  }, []);
  const [medicalRecordScreen, setMedicalRecordscreen] = useState(false);
  const handelMedicalRecordScreen = () => {
    setMedicalRecordscreen(!medicalRecordScreen);
  };

  function gettypeText() {
    const text = {
      inperson: 'In-Person',
      Virtual: 'Virtual',
    };
    return text[data?.consType];
  }
  function gettypePriorityText() {
    const text = {
      regular: 'Routine',
      urgent: 'Urgent',
    };
    return text[data?.priority];
  }
  function getTypeIcon(text, type) {
    const icons = {
      inperson: (
        <div
          className={`${
            type === 'lg' ? 'min-w-10 h-10' : ' min-w-8 h-8'
          } rounded-lg bg-blue-100 flex justify-center items-center`}
        >
          <Person
            className={`${
              type === 'lg' ? 'text-2xl' : 'text-lg'
            } text-blue-700`}
          />
        </div>
      ),
      Virtual: (
        <div
          className={`${
            type === 'lg' ? 'min-w-10 h-10' : ' min-w-8 h-8'
          } rounded-lg bg-purple-100 flex justify-center items-center`}
        >
          <IoVideocam
            className={`${
              type === 'lg' ? 'text-2xl' : 'text-lg'
            } text-purple-700`}
          />
        </div>
      ),
      // Add more cases if needed
    };
    return icons[text] || null; // Return null if type doesn't match any case
  }
  return (
    <section className="bg-black/25 fixed top-0 left-0 w-full h-screen z-50 flex justify-center items-center  lg:z-[10000]">
      <div
        className="absolute top-0 left-0 w-full h-full bg-inherit"
        onClick={closeFunction}
      ></div>
      <div className="bg-bodybackground relative z-20 m-auto overflow-y-auto scrollbar w-full max-h-screen lg:max-w-4xl xl:max-w-5xl lg:py-4 lg:px-8 lg:max-h-[96vh]  lg:rounded-3xl min-h-screen lg:min-h-[auto] overscroll-contain">
        {/* LG Navigation  */}
        <div className="Navigation justify-between items-center hidden py-4 lg:flex ">
        <div className='flex items-center gap-1'>
        {
          data?.method === 'private' &&  <MdWorkspacePremium className='text-[30px] text-[#ffd700]' />
        }
          <p className="text-coalBlack subHeadingText">Consultation View</p>
        </div>
       
          <p
            className="text-navLink baseText !font-medium min-w-10 p-2 cursor-pointer"
            onClick={closeFunction}
          >
            Close
          </p>
        </div>
        <main className="bg-white p-4 pt-0 pb-24 space-y-3.5  lg:rounded-2xl lg:p-0 lg:bg-inherit">
          {/* SM Navigation  */}
          <div className=" bg-white flex items-center gap-1 sticky top-0 py-4 z-30 lg:hidden">
            <FiChevronLeft
              className="text-2xl text-coalBlack w-9 h-9 p-1 rounded-full active:bg-sky-50"
              onClick={closeFunction}
            />
            <p className="subHeadingText  text-coalBlack capitalize">
              Consultation
            </p>
            <p
              className={`baseText px-4 py-1.5 rounded-full  text-white ms-auto ${
                data?.priority === 'urgent' ? 'bg-[#FF4539]' : 'bg-[#7be7b2]'
              }`}
            >
              {gettypePriorityText()}
            </p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2  lg:gap-6 place-content-start">
            <section className="grid grid-cols-1 place-content-start">
              {/* SM profile viewer  start*/}
              <div className="flex mb-4 gap-2 lg:hidden">
                <div className="size-32 basis-32 rounded-xl bg-gray-200">
                  <img
                    src={data?.hospitalcoverPhoto ? `${process.env.REACT_APP_API_URL}/${data?.hospitalcoverPhoto}`:''}
                    className="block object-cover w-full h-full rounded-xl"
                  />
                </div>
                <div className="space-y-3 shrink-[100]">
                  <p className="text-accent headingText capitalize line-clamp-2">
                    {data?.hospitalName}
                  </p>
                  <div className="flex items-center gap-4">
                    <div className="flex justify-center items-center bg-lightBlue rounded-full cursor-pointer">
                      <RiChat1Line className="text-xl min-w-9 h-9 text-accent p-2" />
                    </div>
                    <div className="flex justify-center items-center bg-lightBlue rounded-full cursor-pointer">
                      <IoMdCall className="text-xl min-w-9 h-9 text-accent p-2" />
                    </div>
                    <div className="flex justify-center items-center bg-lightBlue rounded-full cursor-pointer">
                      <IoVideocam className="text-xl min-w-9 h-9 text-accent p-2" />
                    </div>
                  </div>
                </div>
              </div>
              {/* SM profile viewer  end*/}
              {/* LG profile viewer  */}
              <div className="profileForLargeDevice rounded-3xl bg-white p-4 space-y-3 mb-4 hidden lg:block">
                <div>
                  <div className="bg-gray-100 h-36 rounded-2xl ">
                    <Avatar
                      name={data?.hospitalName}
                      src={data?.hospitalProfilePhoto ? `${process.env.REACT_APP_API_URL}/${data?.hospitalProfilePhoto}`:''}
                      size="100%"
                      round={16}
                      textSizeRatio={2}
                   
                    />
                   
                    {/* <img
                      src={data?.hospitalcoverPhoto}
                      className="block object-cover w-full h-full rounded-2xl"
                    /> */}
                  </div>
                  <div className="w-14 h-14 bg-gray-400 rounded-full relative top-[-30px] left-1/2 -translate-x-1/2 border border-white">
                    <Avatar
                      name={data?.hospitalName}
                      src={data?.hospitalProfilePhoto ? `${process.env.REACT_APP_API_URL}/${data?.hospitalProfilePhoto}`:''}
                      size="56"
                      round={true}
                      textSizeRatio={2}
                    />
                  
                  </div>
                </div>
                <div className="px-6 space-y-3 !mt-0">
                  <p className="text-coalBlack text-center line-clamp-2 headingText capitalize ">
                    {data?.hospitalName}
                  </p>
                  <div className="flex items-start gap-2">
                    <FaLocationDot className="text-xl text-accent min-w-4" />
                    <p className="baseText text-navLink">
                      {data?.hospitalAddress}
                    </p>
                  </div>
                  <div className="flex items-start gap-2">
                    <IoMdCall className="text-xl text-accent min-w-4" />
                    <p className="baseText text-navLink">
                      {data?.hospitalContactNumber}
                    </p>
                  </div>
                </div>
              </div>
              <section className="baseText space-y-6 bg-white lg:p-4 lg:rounded-3xl mt-auto">
                <div className="space-y-2 requestDescription">
                  <p className="subHeadingText text-coalBlack ">
                    Request Description
                  </p>
                  <p className="baseText text-navLink">
                    {data?.RequestDescription}
                  </p>
                </div>
                <div className="consultationType space-y-2 lg:hidden">
                  <div className="flex items-center gap-1">
                    <p className="subHeadingText text-coalBlack">
                      Consultation Type
                    </p>
                    <div className="bg-accent w-4 h-4 flex justify-center items-center rounded-full text-white text-[8px]">
                      i
                    </div>
                  </div>
                  <div className="inline-flex items-center gap-3 px-3 py-2 rounded-lg bg-lightBlue">
                    {getTypeIcon(data?.consType, 'sm')}
                    <p className="baseText text-coalBlack">{gettypeText()}</p>
                  </div>
                </div>
                <div className="grid-cols-2 gap-x-2 gap-y-4 consultationTypeLg hidden lg:grid">
                  <div className="bg-white shadow-blue_dropshadow rounded-2xl">
                    <p className="subHeadingText text-[#A3AED0] px-3 py-1">
                      Consultation Type
                    </p>
                    <div className="inline-flex items-center gap-2 px-3 py-1 rounded-lg ">
                      <div className="min-w-8 h-8 rounded-lg bg-purple-100 flex justify-center items-center">
                        {getTypeIcon(data?.consType, 'sm')}
                      </div>
                      <p className="baseText text-coalBlack">{gettypeText()}</p>
                    </div>
                  </div>
                  <div className="bg-white shadow-blue_dropshadow rounded-2xl">
                    <p className="subHeadingText text-[#A3AED0] px-3 py-1">
                      Request Priority
                    </p>
                    <div className="inline-flex items-center gap-2 px-3 py-1 rounded-lg ">
                      <div className="min-w-4 h-8 rounded-lg  flex justify-center items-center">
                        <FaSquareCheck
                          className={`text-lg ${
                            data?.priority === 'urgent'
                              ? 'text-[#FF637B]'
                              : 'text-[#7be7b2]'
                          } `}
                        />
                      </div>
                      <p className="text-sm font-medium text-coalBlack">
                        {data?.priority === 'urgent' ? 'Urgent' : 'Routine'}
                      </p>
                    </div>
                  </div>
                  <div className="bg-white shadow-blue_dropshadow rounded-2xl">
                    <p className="subHeadingText text-[#A3AED0] px-3 py-1">
                      Reference Doctor
                    </p>
                    <div className="inline-flex items-center gap-2 px-3 py-1 rounded-lg ">
                      {/* <div className="min-w-8 h-8 rounded-full bg-gray-200"></div> */}
                      <div className="w-6 h-6 bg-sky-100 rounded-full flex justify-center items-center p-1">
                        <PiStethoscopeFill className="text-lg text-accent" />
                      </div>
                      <p className="text-sm font-medium capitalize  text-coalBlack max-w-36 truncate whitespace-nowrap text-ellipsis">
                        Dr. {data?.doctorDetails?.doctorname}
                      </p>
                    </div>
                  </div>
                  <div className="bg-white shadow-blue_dropshadow rounded-2xl">
                    <p className="font-medium text-base text-[#A3AED0] px-3 py-1">
                      Contact Doctor
                    </p>
                    <div className="inline-flex items-center gap-2 px-3 py-1 rounded-lg ">
                      <IoMdCall className="text-accent text-xl" />
                      <p className="text-sm font-medium text-coalBlack">
                        {data?.doctorDetails?.phone}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="referenceDoctor space-y-2  lg:hidden">
                  <p className="subHeadingText text-coalBlack ">
                    Reference Doctor
                  </p>
                  <div className="grid grid-cols-1  gap-2 md:grid-cols-2">
                    <div className="bg-lightBlue px-2 py-5 rounded-xl">
                      <div className="flex items-center gap-2">
                        <div className="size-10 w-10 rounded-full inline-flex justify-center items-center">
                          <PiStethoscopeFill className="text-2xl text-accent" />
                        </div>
                        <div>
                          <p className="text-coalBlack baseText max-w-[24ch] truncate text-ellipsis">
                            Dr. {data?.doctorDetails?.doctorname}
                          </p>
                          <p className="text-coalBlack font-light text-sm max-w-[24ch] truncate text-ellipsis"></p>
                        </div>
                      </div>
                    </div>
                    <div className="bg-lightBlue px-2 py-5 rounded-xl">
                      <a href={`tel:${data?.doctorDetails?.phone}`}>
                        <div className="flex items-center gap-2">
                          <div className="size-10 w-10 rounded-full inline-flex justify-center items-center">
                            <IoMdCall className="text-accent text-2xl" />
                          </div>
                          <div>
                            <p className="text-coalBlack baseText">
                              {data?.doctorDetails?.phone}
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </section>

              {data?.Consultationstatus && (
                <AttendingDoctor
                  data={data?.AcceptedDoctor}
                  dateaccepted={data?.acceptedDate}
                  consultationid={data?._id}
                  closeFunction={closeFunction}
                  Consultationstatus={data?.status}
                  specialization={data?.Specalaization}
                  disputeDetails={disputeDetails}
                />
              )}
            </section>
            <section className="space-y-6 pb-4 !mt-4 bg-white lg:rounded-3xl lg:!mt-0 lg:p-4 place-content-start h-fit">
              <div className="MedicalDetail space-y-2 hidden">
                <p className="subHeadingText text-coalBlack ">Medical Detail</p>
                <div className="flex flex-wrap gap-3 items-center">
                  {data?.medicalTag?.map((tag, index) => (
                    <div
                      key={index}
                      className="px-4 py-2 rounded-full baseText text-coalBlack bg-gray-100 capitalize"
                    >
                      {tag}
                    </div>
                  ))}
                </div>
              </div>
              <div className="DoctorsDescription space-y-2 hidden">
                <p className="subHeadingText text-coalBlack ">
                  Doctors Description
                </p>
                <p className="baseText text-coalBlack">{data?.doctorNotes}</p>
              </div>
              <div className="PatientDetail space-y-2">
                  <div className="bg-lightBlue p-3 rounded-xl">
                    <div className="inline-flex gap-1 items-center">
                      <div className="size-9  inline-flex justify-center  items-center">
                        {data?.patientGender == 'male' ? (
                          <PiGenderMaleBold className="text-lg text-blue-400 " />
                        ) : (
                          <PiGenderFemaleBold className="text-lg text-blue-400 " />
                        )}
                      </div>
                      <p className="font-medium text-sm">
                        <span className="capitalize me-1">
                          {data?.patientGender},{' '}
                        </span>
                        <span>{data?.patientAge} years</span>
                      </p>
                    </div>
                  </div>
                </div>
              <div className="MedicalRecords space-y-2">
                <div className="flex items-center">
                  <p className="subHeadingText text-coalBlack ">
                    Medical Records
                  </p>
                  <p
                    className="smallText text-navLink font-normal ms-auto cursor-pointer"
                    onClick={handelMedicalRecordScreen}
                  >
                    View All
                  </p>
                </div>
                <div className="flex items-center gap-6 px-2 py-3 rounded-lg bg-lightBlue">
                  <div className="inline-flex flex-col gap-1 justify-center items-center">
                    <BiSolidReport className="text-2xl text-accent" />
                    <p className="baseText text-accent  capitalize">Report</p>
                  </div>
                  <div className="inline-flex flex-col gap-1 justify-center items-center">
                    <FaFilePrescription className="text-2xl text-accent" />
                    <p className="baseText text-accent  capitalize">
                      Prescription
                    </p>
                  </div>
                  <div className="inline-flex flex-col gap-1 justify-center items-center">
                    <GrNotes className="text-2xl text-accent" />
                    <p className="baseText text-accent  capitalize">Notes</p>
                  </div>
                </div>
              </div>
              <div className="HospitalLocation bg-white shadow-card_shadow p-4 rounded-2xl">
                <p className="subHeadingText text-coalBlack mb-2">
                  Hospital Location
                </p>
                {data?.hospitalId?.lat ? (
                  <MapComponent
                    latitude={data?.hospitalId?.lat}
                    longitude={data?.hospitalId?.long}
                  />
                ) : (
                  <MapComponent latitude={0} longitude={0} />
                )}
              </div>
              {/* <div className="doctorPrescription bg-white shadow-card_shadow p-4 rounded-2xl">
                <p className="subHeadingText text-coalBlack ">
                  Doctor Prescription
                </p>
                {data?.Prescription?.record[0]?.filePath ? (
                  <div className="max-h-96 w-full">
                    <img
                      src={data?.Prescription?.record[0]?.filePath}
                      className="block object-cover mx-auto"
                    />
                  </div>
                ) : (
                  <div className="inline-flex gap-2 items-center my-3">
                    <IoAlertCircle className="text-2xl text-red-500" />

                    <p className="baseText text-coalBlack capitalize">
                      Prescription not uploaded by doctor
                    </p>
                  </div>
                )}
              </div> */}
            </section>
          </div>
        </main>
        {medicalRecordScreen && (
          <MedicalRecord
            closeFunction={handelMedicalRecordScreen}
            data={data}
          />
        )}
      </div>
    </section>
  );
}

export default ViewDetails;
