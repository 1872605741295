import React, { useState, useEffect } from "react";
import PaginatedTable from "../TableOdds/PaginatedTable";
import {
  ApproveorReject,
  DeActiveuser,
  getduecheck,
  getHospitalById,
} from "../../apis";
import toast from "react-hot-toast";
import axios from "axios";
import Moment from "react-moment";
import Noprofile from "../../Assets/no-image.png";
import { FaImage } from "react-icons/fa";
import { BlockRequestValidation, RejectRequestValidation } from "../Validation";
import { EditProfileLoader } from "../common/loader";
import { InputErrorMessage } from "../errorMessages";
import { useFormik } from "formik";
import ConfirmPopup from "../confirmPopup/ConfirmPopup";

function ViewInformation({ id, closeFunction }) {
  const [data, setData] = useState(null);
  const [loader, setLoader] = useState(false);

  const getItem = async () => {
    try {
      setLoader(true);
      const res = await getHospitalById(id);
      if (res.status === 200) {
        console.log(res.data);
        setData(res.data);
        setLoader(false); // Set loader to false after data is loaded
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      // toast.error(message, { id: 'error' });
    }
  };

  useEffect(() => {
    if (id) {
      getItem();
    }
  }, [id]);

  const TableHeaders = [
    "Hospital Name",
    "Consultation Type",
    "Appointment Date",
    "Priority",
    "Status",
  ];
  const [requestBlock, setrequestBlock] = useState(false);
  const [RejectLoader, setRejectLoader] = useState(false);

  const Blockformik = useFormik({
    initialValues: {
      Blocknotes: "",
    },
    validationSchema: BlockRequestValidation,
    onSubmit: async (values) => {
      const { Blocknotes } = values;
      console.log(Blocknotes);
      try {
        setRejectLoader(true);
        const res = await getduecheck(id);
        if (res.status === 200) {
          console.log(res.data);
          if (
            res?.data?.data?.duepaid === "false" ||
            res?.data?.data?.duepaid == false
          ) {
            setRejectLoader(false);
            setConfirmPopup(true);
          } else {
            onsubitdel();
          }
        }
      } catch (error) {
        console.log(error);
        toast.error(
          error.response.data.error ||
            "Something Went Wrong, Please try again later",
          { id: "error" }
        );
      } finally {
        setApproveLoader(false);
      }
    },
  });
  const [confirmPopup, setConfirmPopup] = useState(false);
  const handleConfirmPopup = () => {
    setConfirmPopup(!confirmPopup);
    if (confirmPopup) {
      setrequestBlock(false);
      Blockformik.resetForm();
    }
  };
  const onsubitdel = async () => {
    try {
      const res = await DeActiveuser({
        userId: id,
        type: "hospital",
        reason: Blockformik.values.Blocknotes,
      });
      if (res.status === 200) {
        console.log(res);
        toast.success(
          <span className="whitespace-nowrap">{res?.data?.message}</span> ||
            "Hospital deactivated successfully."
        );
        location.reload();
      } else {
        toast.error(
          <span className="whitespace-nowrap">{res?.data?.error}</span> ||
            "Hospital deactivated Failed"
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRejectLoader(false);
    }
  };
  return (
    <div className="bg-white rounded-2xl text-coalBlack py-5">
      {requestBlock ? (
        <>
          {confirmPopup && (
            <ConfirmPopup
              fillBtnTitle={"yes"}
              unFillBtnTitle={"No"}
              message={
                "Are you sure you want to block the hospital? It has pending dues. The hospital will not be able to clear pending dues if deactivated."
              }
              popupStatus={confirmPopup}
              handelConfirmPopUp={handleConfirmPopup}
              onclickFunction={onsubitdel}
            />
          )}
          <form onSubmit={Blockformik.handleSubmit}>
            <div className="p-8 min-w-[35rem]">
              <p className="text-coalBlack subHeadingText">
                Provide reason to deactivate the hospital
              </p>
              <div className="my-10">
                <textarea
                  name="Blocknotes"
                  id="Blocknotes"
                  value={Blockformik.values.Blocknotes}
                  onChange={Blockformik.handleChange}
                  className="border rounded-lg border-gray-200 p-4 w-full"
                  rows="6"
                  placeholder="Enter your reason here"
                ></textarea>
                <InputErrorMessage
                  error={
                    Blockformik.touched.Blocknotes &&
                    Blockformik.errors.Blocknotes
                  }
                />
                <div className="my-2">
                  <div className="flex items-center justify-end gap-5">
                    <button
                      className="px-4 py-2 min-h-10  text-accent border border-accent rounded-lg min-w-28 capitalize"
                      onClick={() => {
                        setrequestBlock(false);
                        Blockformik.resetForm();
                      }}
                    >
                      Back
                    </button>
                    <button
                      className="px-4 py-2 min-h-10 bg-accent text-white rounded-lg min-w-28 capitalize"
                      onClick={() => {}}
                    >
                      {RejectLoader ? <EditProfileLoader /> : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      ) : (
        <>
          <header className="flex items-center sticky top-0 z-10 bg-white p-8 border-b border-gray-100 mb-10">
            <h1 className="text-2xl font-medium capitalize">
              {" "}
              {data?.profile?.hospitalName}
            </h1>
            <div className="ms-auto gap-4 flex items-center">
              <button
                className="px-4 py-2 min-h-10 baseText bg-red-500 text-white rounded-lg min-w-28"
                onClick={() => {
                  setrequestBlock(true);
                }}
              >
                Deactivate Account
              </button>
              <button
                className="capitalize text-base font-medium text-navLink "
                onClick={() => {
                  closeFunction();
                }}
              >
                close
              </button>
            </div>
          </header>
          <main className="space-y-10 px-4">
            <section className="shadow-blue_dropshadow2 p-4 rounded-[58px]">
              <div className=" flex flex-row gap-6 ">
                <div className="max-w-[190px] w-full">
                  {loader ? (
                    <div
                      className="size-[190px] border-[1.5px] border-[#e9e9e9] rounded-[42px] bg-[#f6f6f6] object-cover animate-pulse 
              dark:bg-gray-700 items-center justify-center flex"
                    >
                      <FaImage size={60} className="text-white" />
                    </div>
                  ) : data?.profile?.coverPic ? (
                    <img
                      src={
                        data?.profile.coverPic
                          ? `${process.env.REACT_APP_API_URL}/${data?.profile?.coverPic}`
                          : ""
                      }
                      alt="Cover"
                      className="size-[190px] border-[1.5px] border-[#e9e9e9] rounded-[42px] bg-[#f6f6f6] object-cover"
                    />
                  ) : (
                    <img
                      src={Noprofile}
                      alt="No Profile"
                      className="size-[190px] border-[1.5px] border-[#e9e9e9] rounded-[42px] bg-[#f6f6f6] object-contain"
                    />
                  )}
                </div>
                <section className="flex-1">
                  <h1 className="subHeadingText capitalize mb-1.5 !font-semibold">
                    personal details
                  </h1>
                  <div className="w-full grid lg:grid-cols-2 xl:grid-cols-3 gap-4 place-content-center">
                    <div className="singleField">
                      <p className="text-navyBlue subHeadingText capitalize line-clamp-1">
                        Email
                      </p>
                      <p className="baseText break-all text-coalBlack/75">
                        {data?.profile?.userId?.email}
                      </p>
                    </div>
                    <div className="singleField">
                      <p className="text-navyBlue subHeadingText capitalize line-clamp-1">
                        Phone Number
                      </p>
                      <p className="baseText capitalize line-clamp-1 text-coalBlack/75">
                        {data?.profile?.refCountryCode}{" "}
                        {data?.profile?.refPhoneNo}
                      </p>
                    </div>
                    <div className="singleField">
                      <p className="text-navyBlue subHeadingText capitalize line-clamp-1">
                        Registration ID
                      </p>
                      <p className="baseText capitalize line-clamp-1 text-coalBlack/75">
                        {data?.profile?.regNumber}
                      </p>
                    </div>
                    <div className="singleField col-span-3">
                      <p className="text-navyBlue subHeadingText capitalize line-clamp-1">
                        Address
                      </p>
                      <p className="baseText capitalize line-clamp-2 text-coalBlack/75">
                        {data?.profile?.address}
                      </p>
                    </div>
                  </div>
                </section>
              </div>
              <section className="p-4 pt-8 pb-1.5">
                <h1 className="subHeadingText !font-semibold capitalize mb-1.5 text-coalBlack">
                  Hospital details
                </h1>

                <div className="w-full grid grid-cols-4 gap-4 place-content-center">
                  <div className="singleField">
                    <p className="subHeadingText   capitalize line-clamp-1">
                      Speciality
                    </p>
                    <p className="baseText capitalize line-clamp-1 text-coalBlack">
                      {data?.profile?.speciality}
                    </p>
                  </div>
                  <div className="singleField">
                    <p className="subHeadingText   capitalize line-clamp-1">
                      Latitude
                    </p>
                    <p className="baseText capitalize line-clamp-1 text-coalBlack">
                      {data?.profile?.lat}
                    </p>
                  </div>
                  <div className="singleField">
                    <p className="subHeadingText capitalize line-clamp-1">
                      Longitude
                    </p>
                    <p className="baseText capitalize line-clamp-1 text-coalBlack">
                      {data?.profile?.long}
                    </p>
                  </div>
                  <div className="singleField">
                    <p className="subHeadingText capitalize line-clamp-1">
                      Hospital Capacity
                    </p>
                    <p className="baseText capitalize line-clamp-1 text-coalBlack">
                      {data?.profile?.hospitalCapacity}
                    </p>
                  </div>
                </div>
              </section>
            </section>
            <section className="px-4">
              <div className="flex items-center">
                <h1 className="headingText capitalize">consultation history</h1>
              </div>
              <div className="mt-8">
                <PaginatedTable
                  itemsPerPage={10}
                  tableHeaders={TableHeaders}
                  items={data?.consultationHistory?.map((item) => {
                    return {
                      hositalName: (
                        <p className="line-clamp-1">{item?.hositalName}</p>
                      ),
                      apppointmneType:
                        item.apppointmneType === "inperson"
                          ? "In-person"
                          : "Remote",
                      appointmentDate: (
                        <Moment
                          format="DD/MM/YYYY"
                          date={item.appointmentDate}
                        />
                      ),
                      priority:
                        item.priority === "regular" ? "General" : "Urgent",
                      status: item.status,
                    };
                  })}
                />
              </div>
            </section>
          </main>
        </>
      )}
    </div>
  );
}

export default ViewInformation;
