import React, { useState } from 'react';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
// import { ConsultationCompletedAPI, ConsultationbyID } from '../../apis';
import toast from 'react-hot-toast';
import { EditProfileLoader } from '../common/loader';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { IoAlertCircle } from 'react-icons/io5';
import { updateDispute } from '../../apis';
import ConfirmPopup from '../confirmPopup/ConfirmPopup';

function ConsultationStatusBtn({
  consultationid,
  closeFunction,
  Consultationstatus,
  disputeDetails,
  doctorDetails,
}) {
  console.log(Consultationstatus);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleDispute = async () => {
    const payload = {
      DisputeId: disputeDetails?._id,
      doctorId: doctorDetails?._id,
    };
    console.log(payload);
    try {
      const res = await updateDispute(payload);
      if (res.status === 200) {
        toast.success(res?.data?.message);
        handlePopup();
        location.reload();
        
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.error || 'Something went wrong');
    }
  };
  const handlePopup = () => {
    setConfirmPopup(!confirmPopup);
  };
  return (
    <>
      {Consultationstatus === 'completed' ? (
        <div className="my-4 gap-2 mx-auto py-2 px-4 text-coalBlack bg-white  min-h-11 rounded-2xl w-full flex items-center ">
          <div>
            <IoAlertCircle className="text-2xl text-accent" />
          </div>
          <p className=" inline-block  text-center font-medium capitalize">
            consultation completed from Hospital
          </p>
        </div>
      ) : Consultationstatus === 'no-show' ? (
        <>
        {
          disputeDetails ? 
          <div className="baseText bg-white mt-4 lg:p-4 lg:rounded-3xl">
          <SimpleButton
            title={'Revert No Show'}
            customClass={
              'text-red-500  border border-red-500 rounded-lg w-full hover:bg-red-500 hover:text-white'
            }
            onClickEvent={handlePopup}
          />
        </div>
          :
          <div className="my-4 inline-flex items-center gap-2 mx-auto py-2 px-4 text-red-600 bg-red-200 min-h-11 rounded-2xl w-full flex items-center justify-center">
          <div>{/* <IoMdCheckmarkCircle className="text-2xl" /> */}</div>
          <p className=" inline-block  text-center font-medium capitalize">
            Reported No Show
          </p>
        </div>
        }
         </>
      ) : null}

      {confirmPopup && (
        <ConfirmPopup
          message={'Are you sure you want to revert this consultation No-Show?'}
          fillBtnTitle={'Yes'}
          unFillBtnTitle={'No'}
          popupStatus={confirmPopup}
          handelConfirmPopUp={handlePopup}
          onclickFunction={handleDispute}
        />
      )}
    </>
  );
}

export default ConsultationStatusBtn;
