import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../appContext';
import editIcon from '../../Icons/editIcon.svg';
import logoutIcon from '../../Icons/logoutIcon.svg';
import { assignBucketUrl } from '../../utils/helperFunctions';
import ProfileDefault from '../../Assets/img/covers/userDefault.png';
import './sidebar.css';
import { FaUsers } from 'react-icons/fa';
import { HiOutlineHome } from 'react-icons/hi2';
import { FiClock } from 'react-icons/fi';
import { BsCreditCard2BackFill } from 'react-icons/bs';
import { BiSolidReport } from 'react-icons/bi';
import { FiLogOut } from 'react-icons/fi';
import logoImg from '../../Assets/logo/odds_logo_blue.png';
import { IoMdSettings } from 'react-icons/io';
import { MdReport } from 'react-icons/md';
export default function Sidebar() {
  useEffect(() => {
    if (localStorage.getItem('ActiveNavigation') == null) {
      localStorage.setItem('ActiveNavigation', '/');
    }
  }, []);
  const URL = window.location;
  // hide mobile navigation for particular route
  var mobNavHide = '';
  if (
    URL.pathname === '/notification' ||
    URL.pathname === '/profile' ||
    URL.pathname === '/profile/details' ||
    URL.pathname === '/profile/appoinment' ||
    URL.pathname === '/profile/settings/changepassword' ||
    URL.pathname === '/profile/settings' ||
    URL.pathname === '/profile/help' ||
    URL.pathname === '/profile/deleteaccount' ||
    URL.pathname === '/profile/logout'
  ) {
    mobNavHide = 'hidden';
  }
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { state, logout } = appContext;
  const { loggedInUser, isLoggedIn } = state;
  const handleLogout = () => {
    logout(navigate);
  };
  const [profileDrawerOpen, setProfileDrawerOpen] = useState(false);

  function handelNavStatus(prop) {
    localStorage.setItem('ActiveNavigation', prop);
  }

  const NavLinks = [
    {
      linkTo: '/',
      icon: (
        <div className="grid grid-cols-2 gap-1">
          <div className="dashGrid w-2.5 h-2.5 bg-navLink rounded-sm"></div>
          <div className="dashGrid w-2.5 h-2.5 bg-navLink/25 rounded-sm opacity-75"></div>
          <div className="dashGrid w-2.5 h-2.5 bg-navLink rounded-sm"></div>
          <div className="dashGrid w-2.5 h-2.5 bg-navLink rounded-sm"></div>
        </div>
      ),
      mobIcon: <HiOutlineHome className="text-2xl text-coalBlack m-auto" />,
      name: 'Dashboard',
    },
    {
      linkTo: '/hospitalmanagement',
      icon: <FaUsers className="text-2xl text-inherit" />,
      mobIcon: <FiClock className="text-2xl text-coalBlack m-auto" />,
      name: 'Hospital Management',
    },
    {
      linkTo: '/doctormanagement',
      icon: <FaUsers className="text-2xl text-inherit" />,
      mobIcon: <FiClock className="text-2xl text-coalBlack m-auto" />,
      name: 'Doctor Management',
    },

    // {
    //   linkTo: '/subscription',
    //   icon: <FaArrowsRotate className="text-2xl text-inherit" />,
    //   mobIcon: <FaArrowsRotate className="text-2xl text-coalBlack m-auto" />,
    //   name: 'Subscriptions',
    // },
    {
      linkTo: '/paymentmanagement',
      icon: <BsCreditCard2BackFill className="text-2xl text-inherit" />,
      mobIcon: '',
      name: 'Payment Management',
    },
    {
      linkTo: '/payoutmanagement',
      icon: <FaUsers className="text-2xl text-inherit" />,
      mobIcon: <FiClock className="text-2xl text-coalBlack m-auto" />,
      name: 'Payout Management',
    },
    {
      linkTo: '/consultationHistory',
      icon: <FaUsers className="text-2xl text-inherit" />,
      mobIcon: <FiClock className="text-2xl text-coalBlack m-auto" />,
      name: 'Consultation History',
    },
    {
      linkTo: '/disputedconsultation',
      icon: <MdReport className="text-2xl text-inherit" />,
      mobIcon: '',
      name: 'Disputed Consultation',
    },
    {
      linkTo: '/settings',
      icon: <IoMdSettings className="text-2xl text-inherit" />,
      mobIcon: '',
      name: 'Settings Management',
    },
    {
      linkTo: '/reports',
      icon: <BiSolidReport className="text-2xl text-inherit" />,
      mobIcon: '',
      name: 'Reports',
    },

    {
      // linkTo: '',
      icon: <FiLogOut className="text-2xl text-inherit" />,
      mobIcon: <FiLogOut className="text-2xl text-coalBlack m-auto" />,
      name: 'Logout',
      onclick: handleLogout,
    },
  ];

  // const desktopLinks = NavLinks.map((data, index) => {
  //   return (
  //     <Link
  //       to={data.linkTo}
  //       className={`sidebar-li inline-block rounded-md ${
  //         URL.pathname === data.linkTo ? 'active' : ''
  //       } `}
  //       key={index}
  //       onClick={() => {
  //         handelNavStatus(data.linkTo);
  //       }}
  //     >
  //       <li
  //         className={' flex gap-4 items-center text-navLink p-2 '}
  //         onClick={() => {
  //           handelNavStatus(data.linkTo);
  //         }}
  //       >
  //         <div className="w-6 h-6">{data.icon}</div>
  //         <p className="text-navLink baseText">{data.name}</p>
  //       </li>
  //     </Link>
  //   );
  // });
  const desktopLinks = NavLinks.map((data, index) => (
    <React.Fragment key={index}>
      {data.linkTo ? (
        <Link
          to={data.linkTo}
          className={`sidebar-li inline-block rounded-md ${
            window.location.hash === `#${data.linkTo}` ? 'active' : ''
          } `}          
          onClick={() => handelNavStatus(data.linkTo)}
        >
          <li className="flex gap-4 items-center text-navLink p-2">
            <div className="w-6 h-6">{data.icon}</div>
            <p className="text-navLink baseText">{data.name}</p>
          </li>
        </Link>
      ) : (
        <li
          className="flex gap-4 items-center text-navLink p-2 sidebar-li inline-block rounded-md cursor-pointer"
          onClick={data.onclick} // Provide a default or use the specific handler
        >
          <div className="w-6 h-6">{data.icon}</div>
          <p className="text-navLink baseText">{data.name}</p>
        </li>
      )}
    </React.Fragment>
  ));

  const mobLinks = NavLinks.map((data, i) => {
    if (data.mobIcon != '') {
      return (
        <Link
          to={data.linkTo}
          key={i}
          className={`mobNav rounded-lg ${
            localStorage.getItem('ActiveNavigation') === data.linkTo
              ? 'active'
              : ''
          }`}
          onClick={() => {
            handelNavStatus(data.linkTo);
          }}
        >
          <li
            className={
              ' w-[50px] h-[50px] rounded-lg flex justify-center items-center '
            }
          >
            {data.mobIcon}
          </li>
        </Link>
      );
    }
  });

  return (
    <>
      <section
        className={
          'sidebar-container w-1/4 max-w-[258px] !bg-white hidden lg:block'
        }
      >
        <div className="sidebar-top-section">
          <ul className="sidebar-ul space-y-3 px-2">
            <li className="sidebar-li my-0 text-center oddsLogo h-[78px] flex justify-start items-center">
              <Link to="/" className="flex items-center gap-2 grow">
                <span className="text-black text-lg md:text:2xl font-semibold tracking-widest rounded-full overflow-hidden">
                  <img src={logoImg} className="size-10 " />
                </span>
                <span className="text-blackV1 headingText">MODO</span>
              </Link>
            </li>
            {desktopLinks}
          </ul>
        </div>

        {/* profile drawer */}
        {profileDrawerOpen && (
          <div className="sidebar-profile-drawer !fixed !bottom-16 !top-auto">
            <ul className="sidebar-profile-drawer-ul">
              <li className="sidebar-profile-drawer-li">
                <div className="sidebar-profile-drawer-icon">
                  <img
                    alt="..."
                    src={assignBucketUrl(loggedInUser?.data?.[0]?.profilePic)}
                    onError={(event) => {
                      event.target.src = ProfileDefault;
                      event.onerror = null;
                    }}
                    id="sidebar-profile-drawer-avatar"
                  />
                </div>
                <p className="sidebar-profile-drawer-title">{`${loggedInUser?.data[0]?.firstName} ${loggedInUser?.data[0]?.lastName}`}</p>
              </li>
              <li className="sidebar-profile-drawer-li">
                <img
                  id="sidebar-profile-drawer-icon"
                  src={editIcon}
                  alt="edit profile button"
                  className="sidebar-profile-drawer-icon"
                />
                <Link
                  to="/edit-profile"
                  className="sidebar-profile-drawer-title "
                >
                  <p id="sidebar-profile-drawer-link">Edit Profile</p>
                </Link>
              </li>
              <li className="sidebar-profile-drawer-li">
                <img
                  id="sidebar-profile-drawer-icon"
                  src={logoutIcon}
                  alt="edit profile button"
                  className="sidebar-profile-drawer-icon"
                />
                <p
                  className="sidebar-profile-drawer-title"
                  id="sidebar-profile-drawer-link"
                  onClick={handleLogout}
                >
                  Sign out
                </p>
              </li>
            </ul>
          </div>
        )}
      </section>
      {/* mobile devices navigation  */}
      <section
        className={`fixed bottom-0 z-[888] py-2 px-4 w-full bg-white lg:hidden ${mobNavHide}`}
      >
        <ul className="flex items-center justify-evenly gap-4 ">{mobLinks}</ul>
      </section>
    </>
  );
}
