import React, { useState, useEffect } from 'react';
import PaginatedTable from '../TableOdds/PaginatedTable';
import { Select } from 'react-select';
import { DeActiveuser, getDoctorById, getHospitalById } from '../../apis';
import Moment from 'react-moment';
import Noprofile from '../../Assets/no-image.png';
import { FaImage } from 'react-icons/fa';
import { BlockRequestValidation, RejectRequestValidation } from '../Validation';
import { EditProfileLoader } from '../common/loader';
import { InputErrorMessage } from '../errorMessages';
import { useFormik } from 'formik';
import ConfirmPopup from '../confirmPopup/ConfirmPopup';
import toast from 'react-hot-toast';

function ViewInformation({ id, closeFunction }) {
  const [data, setdata] = useState(null);
  const [Loader, setLoader] = useState(false);
  const getItem = async () => {
    try {
      setLoader(true);
      const res = await getDoctorById(id);
      if (res.status === 200) {
        console.log(res.data);
        setdata(res.data);
        // toast.success(res.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      //toast.error(message, { id: 'error' });
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (id) {
      getItem();
    }
  }, []);
  const TableHeaders = [
    'Hospital Name',
    'Consultation Number',
    'Appointment Type',
    'Appointment Date',
    'Priority',
    'payment',
  ];
  const date = new Date(data?.profile?.birthDate);
  const formattedDate = date.toLocaleString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  const [requestBlock, setrequestBlock] = useState(false);
   const [RejectLoader, setRejectLoader] = useState(false);
   
  const Blockformik = useFormik({
    initialValues: {
      Blocknotes:''
    },
     validationSchema: BlockRequestValidation,
    onSubmit: async (values) => {
      setRejectLoader(true);
      try {
       
        const res = await DeActiveuser({
        "userId":id,
        "type": "doctor",
        "reason": values.Blocknotes
        });
        if (res.status === 200) {
          setRejectLoader(false);
          console.log(res);
          toast.success(
            <span className="whitespace-nowrap">{res?.data?.message}</span> ||
              'Doctor deactivated successfully.'
          );
          location.reload();
        }
        else{
          setRejectLoader(false);
          toast.success(
            <span className="whitespace-nowrap">{res?.data?.error}</span> ||
              'Doctor deactivated Failed'
          );
        }
      } catch (error) {
        setRejectLoader(false);
        console.log(error);
        toast.error(error?.response?.data?.error);
      }
     
      }
    
  });

  return (
    <div className="bg-white  rounded-2xl text-coalBlack pb-5 pt-0">
       {requestBlock ? (
              <>
                       <form onSubmit={Blockformik.handleSubmit}>
                    <div className="p-8 min-w-[35rem]">
                      <p className="text-coalBlack subHeadingText">
                        Provide reason to deactivate the doctor
                      </p>
                      <div className="my-10">
                        <textarea
                         name="Blocknotes"
                         id='Blocknotes'
                         value={Blockformik.values.Blocknotes}
                         onChange={Blockformik.handleChange}
                          className="border rounded-lg border-gray-200 p-4 w-full"
                          rows="6"
                          placeholder="Enter your reason here"
                        ></textarea>
                         <InputErrorMessage  error={
                                                        Blockformik.touched.Blocknotes &&
                                                        Blockformik.errors.Blocknotes
                                                      } />
                        <div className="my-2">
                          <div className="flex items-center justify-end gap-5">
                            <button
                              className="px-4 py-2 min-h-10  text-accent border border-accent rounded-lg min-w-28 capitalize"
                              onClick={() => {
                                setrequestBlock(false);
                                Blockformik.resetForm();
                              }}
                            >
                              Back
                            </button>
                            <button
                              className="px-4 py-2 min-h-10 bg-accent text-white rounded-lg min-w-28 capitalize"
                             type='submit'
                            >
                              {RejectLoader ? <EditProfileLoader /> : 'Submit'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    </form>
                    </>
                  ):
               (
                <> 
      <header className="flex items-center sticky top-0 z-10 bg-white p-8 py-4  border-b border-gray-100 mb-6">
        <h1 className="headingText capitalize">Doctor information</h1>
        <div className='ms-auto space-x-6'>
          <div className='inline-flex gap-x-2 px-6 border-r border-gray-200'>
            <p className="inline-block  capitalize baseText text-coalBlack">
            Reported No show
            </p>
            <span className='bg-red-500 text-white baseText md:!font-medium rounded-full p-1 size-6 flex justify-center items-center'>
              {data?.profile?.NoShowCount}
            </span>
          </div>
          <button
              className="px-4 py-2 min-h-10 baseText bg-red-500 text-white rounded-lg min-w-28"
              onClick={() => {
                setrequestBlock(true);
              }}
            >
              Deactivate Account 
            </button>
          <button
            className="capitalize text-base font-medium text-navLink ms-auto"
            onClick={() => {
              closeFunction();
            }}
          >
            close
          </button>
        </div>
      </header>
      <main className="space-y-10 px-4">
        <section className="shadow-blue_dropshadow2 p-4 rounded-[58px]">
          <div className=" flex flex-row gap-6 ">
            <div className="max-w-[190px] w-full">
              {Loader ? (
                <div
                  className="size-[190px] border-[1.5px] border-[#e9e9e9] rounded-[42px] bg-[#f6f6f6] object-cover animate-pulse 
              dark:bg-gray-700 items-center justify-center flex"
                >
                  <FaImage size={60} className="text-white" />
                </div>
              ) : data?.profile?.coverPic ? (
                <img
                  src={data?.profile.coverPic ? `${process.env.REACT_APP_API_URL}/${data?.profile?.coverPic}`:''}
                  alt="Cover"
                  className="size-[190px] border-[1.5px] border-[#e9e9e9] rounded-[42px] bg-[#f6f6f6] object-cover"
                />
              ) : (
                <img
                  src={Noprofile}
                  alt="No Profile"
                  className="size-[190px] border-[1.5px] border-[#e9e9e9] rounded-[42px] bg-[#f6f6f6] object-contain"
                />
              )}
            </div>
            <section className="flex-1">
              <h1 className="subHeadingText capitalize mb-1.5 !font-semibold">
                personal details
              </h1>
              <div className="w-full grid lg:grid-cols-2 xl:grid-cols-3 gap-4 place-content-center">
                <div className="singleField">
                  <p className="subHeadingText   capitalize line-clamp-1">
                    Name
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.DoctorName}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText   capitalize line-clamp-1">
                    Email
                  </p>
                  <p className="baseText  break-all text-coalBlack">
                    {data?.profile?.userId?.email}
                  </p>
                </div>

                <div className="singleField">
                  <p className="subHeadingText   capitalize line-clamp-1">
                    phone number
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.countryCode}{' '}
                    {data?.profile?.reference?.contactNo}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText   capitalize line-clamp-1">
                    DOB
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {formattedDate}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText   capitalize line-clamp-1">
                    gender
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.gender}
                  </p>
                </div>
              </div>
            </section>
          </div>
          <section className="p-4 pt-8 pb-1.5">
            <h1 className="subHeadingText !font-semibold capitalize mb-1.5 text-coalBlack">
              Professional details
            </h1>

            <div className="w-full grid grid-cols-4 gap-4 place-content-center">
              <div className="singleField">
                <p className="subHeadingText   capitalize line-clamp-1">
                  Specialization
                </p>
                <p className="baseText capitalize line-clamp-1 text-coalBlack">
                  {data?.profile?.specialization?.name}
                </p>
              </div>
              <div className="singleField">
                <p className="subHeadingText   capitalize line-clamp-1">
                  Registration ID
                </p>
                <p className="baseText capitalize line-clamp-1 text-coalBlack">
                  {data?.profile?.regNumber}
                </p>
              </div>
              <div className="singleField">
                <p className="subHeadingText capitalize line-clamp-1">
                  State Medical Council
                </p>
                <p className="baseText capitalize line-clamp-1 text-coalBlack">
                  {data?.profile?.smCouncil}
                </p>
              </div>
              <div className="singleField">
                <p className="subHeadingText capitalize line-clamp-1">
                  Date of Registration
                </p>
                <p className="baseText capitalize line-clamp-1 text-coalBlack">
                  <Moment format="DD/MM/YYYY" date={data?.profile.dateOfReg} />
                </p>
              </div>
            </div>
            <div className="mt-6">
              <h1 className="subHeadingText !font-semibold capitalize mb-1.5 text-coalBlack">
                Reference Contact
              </h1>
              <div className="w-full grid grid-cols-4 gap-4 place-content-center">
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">Name</p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.reference?.contactName}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    Contact Number
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.countryCode}{' '}
                    {data?.profile?.reference?.contactNo}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <h1 className="subHeadingText !font-semibold capitalize mb-1.5 text-coalBlack">
                Practicing Hospital
              </h1>
              <div className="w-full grid grid-cols-4 gap-4 place-content-center">
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    Hospital Name
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.practiceHospital || '-'}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    Hospital Number
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.hospitalNumber || '-'}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    Hospital Mobile Number
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.hospitalMobileNumber || '-'}
                  </p>
                </div>
              </div>
            </div>
          </section>
        </section>

        <section className="px-4">
          <div className="flex items-center">
            <h1 className="headingText capitalize">consultation history</h1>
          </div>
          <div className="mt-6">
            <PaginatedTable
              itemsPerPage={10}
              tableHeaders={TableHeaders}
              items={data?.consultationHistory?.map((item) => {
                return {
                  hositalName: (
                    <p className="line-clamp-1">{item?.hositalName}</p>
                  ),
                  _id: item._id,
                  apppointmneType:
                    item?.apppointmneType === 'inperson'
                      ? 'In-person'
                      : 'Remote',
                  appointmentDate: (
                    <Moment format="DD/MM/YYYY" date={item.appointmentDate} />
                  ),
                  priority: item?.priority === 'regular' ? 'General' : 'Urgent',
                  Payment: <center>{item?.payment?.[0]} </center>,
                };
              })}
            />
          </div>
        </section>
      </main>
      </>
               )}
    </div>
  );
}

export default ViewInformation;
