import React, { useState, useRef } from 'react';
import AdminLayout from '../../components/admin-layout';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';
import { FaFilter } from 'react-icons/fa';
import ResponsiveTable from '../../components/TableOdds/ResponsiveTable';
import PaginatedTable from '../../components/TableOdds/PaginatedTable';
import CreatePlane from '../../components/subscriptionComponent/CreatePlane';
import MainOddsPopup from '../../components/Modals/MainOddsPopup';
import ExistingPlan from '../../components/subscriptionComponent/ExistingPlan';

import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import ConsultationHistoryTable from '../../components/TypesenseList/ConsultationHistortTable';
import ViewDetails from '../../components/ConsultationCard/ViewDetails';

function HistoryIndex() {
  const [sortOpt, setSortOpt] = useState('asc');
  const [createPlan, setCreatePlan] = useState(false);
  const [existingPlan, setExistingPlan] = useState(false);

  const handleCreatePlan = () => {
    setCreatePlan(!createPlan);
  };
  const handleExistingPlan = () => {
    setExistingPlan(!existingPlan);
  };
  const titleTab = {
    active: true,
    heading: 'Consultation History',
    btnBlock: false,
    btn: null,
    imgBlock: true,
    img: (
      <img
        src={require('../../Assets/img/odds/Time Machine.png')}
        className="object-cover rounded-full"
      />
    ),
  };
  const typesenseInitiate = () => {
    try {
      const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
          apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
          nodes: [
            {
              host: process.env.REACT_APP_HOST_NAME_NEW,
              protocol: 'https',
            },
          ],
        },
        additionalSearchParameters: {
          collection: 'odds-consultation-history',
          sort_by: `CreatedDate:${(sortOpt==='asc') ? 'desc':'asc'}`,
          query_by: 'DoctorName,HospitalName,Specalaization'
        },
      });
      return typesenseInstantsearchAdapter.searchClient;
    } catch (e) {
      return 'failed';
    }
  };
  const searchInputRef = useRef(null);
  const searchClient = typesenseInitiate();
  const [searchresult, setsearchresult] = useState(0);


  // const handelViewRequestBlock = () => {
  //   setViewDetails(!viewDetails);
  // };
  return (
    <AdminLayout titleTab={titleTab}>
      <div className="p-6">
        <section className=" bg-white rounded-2xl p-5 relative">
          <div className="flex items-center capitalize">
            <div className="capitalize font-medium">
              <p className="baseText text-coalBlack">List of Hospitals</p>
            </div>
            <div className="ms-auto">
              <div className="inline-flex items-center gap-5">
                <div className="relative"></div>
                <div className="inline-block bg-accent rounded-lg p-2 shadow-blue_dropshadow relative cursor-pointer group/sort">
                  <FaFilter className="text-2xl text-white" />
                  <div className="absolute top-full -left-20 bg-white rounded-md shadow-md inline-flex flex-col p-1 gap-1 w-[125px] invisible group-hover/sort:visible z-10">
                    <p
                      className={`${
                        sortOpt == 'asc'
                          ? 'text-white bg-accent hover:!bg-accent'
                          : ''
                      } hover:bg-slate-50 text-base px-2 rounded-md py-1 `}
                      onClick={() => {
                        setSortOpt('asc');
                      }}
                    >
                      Newest
                    </p>
                    <p
                      className={`${
                        sortOpt == 'desc'
                          ? 'text-white bg-accent hover:!bg-accent'
                          : ''
                      } hover:bg-slate-50 text-base px-2 rounded-md py-1`}
                      onClick={() => {
                        setSortOpt('desc');
                      }}
                    >
                      Oldest
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-10">
            <ConsultationHistoryTable
              searchClient={searchClient}
              searchInputRef={searchInputRef}
              setsearchresult={setsearchresult}
            />
          </div>
          {createPlan && (
            <MainOddsPopup closeFunction={handleCreatePlan}>
              <CreatePlane closeFunction={handleCreatePlan} />
            </MainOddsPopup>
          )}
          {existingPlan && (
            <MainOddsPopup closeFunction={handleExistingPlan}>
              <ExistingPlan closeFunction={handleExistingPlan} />
            </MainOddsPopup>
          )}
        </section>
        
      </div>
    </AdminLayout>
  );
}

export default HistoryIndex;
