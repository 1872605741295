import React, { useState } from 'react';
import { IoAddCircle } from 'react-icons/io5';
import MainOddsPopup from '../../components/Modals/MainOddsPopup';
import { useFormik } from 'formik';
import { InputErrorMessage } from '../../components/errorMessages';
import { AddSpecializationValidation } from '../../components/Validation';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';
import ConfirmPopup from '../../components/confirmPopup/ConfirmPopup';
import { createSpecialization } from '../../apis';
import toast from 'react-hot-toast';

function AddSpecialization({handleReload}) {
  const [addSpl, setAddSpl] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleAddSplPopup = () => {
    setAddSpl(!addSpl);
  };
  const formik = useFormik({
    initialValues: {
      name: '',
      Amount: '',
    },
    validationSchema: AddSpecializationValidation,

    onSubmit: async (values) => {
      console.log(values);
      handleConfirmPopup();
    },
  });
  const { handleSubmit, handleChange, values, touched, errors, resetForm } = formik;
  const handleConfirmPopup = () => {
    console.log(formik.isValid);
    if (!confirmPopup && formik.isValid) {
      setConfirmPopup(true);
    } else {
      setConfirmPopup(false);
    }
  };
  const toAddNewSpecialization = async () => {
    setLoader(true);
    try {
      const res = await createSpecialization(formik.values);
      if (res.status == 200) {
        setLoader(false);
        handleReload();
        setConfirmPopup(false);
        handleAddSplPopup();
        toast.success(<p className='baseText whitespace-nowrap'>specialization created successfully</p>);
        resetForm({
          values: {
            name: '',
            Amount: '',
          },
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Something went wrong');
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  return (
    <>
      {' '}
      <div
        className="flex gap-x-1 ms-auto items-center px-4 py-2.5 rounded-full bg-accent text-white cursor-pointer hover:bg-accent/75"
        onClick={handleAddSplPopup}
      >
        <IoAddCircle className="text-2xl text-white" />
        <p className="baseText text-white cursor-pointer">Add Specialization</p>
      </div>
      {addSpl && (
        <MainOddsPopup closeFunction={handleAddSplPopup}>
          <div className="w-full p-4 bg-white text-coalBlack rounded-xl min-w-[420px]">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-lg capitalize font-medium ">
                Add New Specialization
              </h3>
              <p
                className="baseText text-navLink ms-auto cursor-pointer"
                onClick={handleAddSplPopup}
              >
                Discard
              </p>
            </div>
            <form onSubmit={formik.handleSubmit} className="pb-6 space-y-2">
              <div className="name">
                <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
                  Specialization Name
                </p>
                <div className="relative">
                  <input
                    type="text"
                    name="name"
                    className={
                      Boolean(formik.touched.name) &&
                      Boolean(formik.errors.name)
                        ? 'form-control border-danger transparent-input-border'
                        : 'form-control transparent-input-border'
                    }
                    id="name"
                    placeholder="Enter Specialization name"
                    value={formik.values.name}
                    onChange={handleChange}
                  />
                  <InputErrorMessage
                    error={formik.touched.name && formik.errors.name}
                  />
                </div>
              </div>
              <div className="amount">
                <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
                  Specialization Amount
                </p>
                <div className="relative">
                  <input
                    type="text"
                    name="Amount"
                    className={
                      Boolean(formik.touched.Amount) &&
                      Boolean(formik.errors.Amount)
                        ? 'form-control border-danger transparent-input-border'
                        : 'form-control transparent-input-border'
                    }
                    id="Amount"
                    placeholder="Enter Specialization Amount"
                    value={formik.values.Amount}
                    onChange={handleChange}
                  />
                  <InputErrorMessage
                    error={formik.touched.Amount && formik.errors.Amount}
                  />
                </div>
              </div>
              <button
                className="btn btn_primary_black text-white w-full rounded-xl"
                type="submit"
              >
                Add Specialization
              </button>
            </form>
          </div>
          {confirmPopup && (
            <ConfirmPopup
              message={
                <p>
                  Are you sure you want to add a <br /> "{formik?.values?.name}"
                  Specialization?
                </p>
              }
              popupStatus={confirmPopup}
              onclickFunction={toAddNewSpecialization}
              handelConfirmPopUp={handleConfirmPopup}
            />
          )}
        </MainOddsPopup>
      )}
    </>
  );
}

export default AddSpecialization;
