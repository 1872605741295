import React, { useEffect, useState, useContext } from 'react';
import { FaAddressCard, FaImage } from 'react-icons/fa';
import { PiCertificateFill } from 'react-icons/pi';
import { LuDownload } from 'react-icons/lu';
import { ApproveorReject, getHospitalById } from '../../apis';
import toast from 'react-hot-toast';
import { AppContext } from '../../appContext';
import { EditProfileLoader } from '../common/loader';
import Noprofile from '../../Assets/no-image.png';
import { saveAs } from 'file-saver';
import { useFormik } from 'formik';
import { RejectRequestValidation } from '../Validation';
import { InputErrorMessage } from '../errorMessages';
function ViewNewRequest({ id, refresh, closeFunction }) {
  const [requestDecline, setRequestDecline] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [ApproveLoader, setApproveLoader] = useState(false);
  const [RejectLoader, setRejectLoader] = useState(false);
  const [data, setdata] = useState(null);
  const getItem = async () => {
    try {
      setLoader(true);
      const res = await getHospitalById(id);
      if (res.status === 200) {
        console.log(res.data);
        setdata(res.data);
        // toast.success(res.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      //toast.error(message, { id: 'error' });
    } finally {
      setLoader(false);
    }
  };
  const rejectformik = useFormik({
    initialValues: {
      rejectnotes:''
    },
     validationSchema: RejectRequestValidation,
    onSubmit: async (values) => {
     const {rejectnotes}=values;
     try {
      setRejectLoader(true);
      const res = await ApproveorReject({
        type: 'hospital',
        status: 'Reject',
        hospitalid: id,
        ApproverId: userDetails?.Profile?._id || '',
        rejectnotes: rejectnotes,
      });
      if (res.status === 200) {
        console.log(res.data);
        setdata(res.data);
        toast.success(res.data.message);
        location.reload();
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.error ||
        'Something Went Wrong, Please try again later',
        { id: 'error' }
      );
    } finally {
      setApproveLoader(false);
    }
      }
    
  });
  useEffect(() => {
    if (id) {
      getItem();
    }
  }, []);

  const { userDetails } = useContext(AppContext);

  const ApproveRequest = async () => {
    try {
      setApproveLoader(true);
      const res = await ApproveorReject({
        type: 'hospital',
        status: 'Approve',
        hospitalid: id,
        ApproverId: userDetails?.Profile?._id || '',
      });
      if (res.status === 200) {
        console.log(res.data);
        setdata(res.data);
        toast.success(res.data.message);
        location.reload();
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.error ||
        'Something Went Wrong, Please try again later',
        { id: 'error' }
      );
    } finally {
      setApproveLoader(false);
    }
  };
  
  const [rejectfeedback, setrejectfeedback] = useState('');
  return (
    <div className="bg-white  rounded-2xl text-coalBlack py-5 max-w-xl ">
      <header className="sticky top-0 z-10 bg-white px-8 pb-4 space-y-4 border-b border-gray-100 gap-6">

        <div className="flex items-center bg-white gap-5">
          <h1 className="headingText capitalize pr-3">Hospital registration</h1>
          <button
            className="capitalize text-base font-medium text-navLink ms-auto"
            onClick={() => {
              closeFunction();
            }}
          >
            close
          </button>
        </div>
        <div className="inline-flex gap-4 items-center ms-auto">
          {!requestDecline ? (
            <button
              className="px-4 py-2 min-h-10 baseText bg-green-500 text-white rounded-lg min-w-28"
              onClick={() => {
                ApproveRequest();
              }}
            >
              {ApproveLoader ? <EditProfileLoader /> : 'Approve'}
            </button>
          ) : null}
          {!requestDecline && (
            <button
              className="px-4 py-2 min-h-10 baseText bg-red-500 text-white rounded-lg min-w-28"
              onClick={() => {
                setRequestDecline(true);
              }}
            >
              Decline
            </button>
          )}
        </div>
      </header>
      {requestDecline ? (
           <form onSubmit={rejectformik.handleSubmit}>
        <div className="p-8 min-w-[35rem]">
          <p className="text-coalBlack subHeadingText">
            Provide reason to decline verification request
          </p>
          <div className="my-10">
            <textarea
             name="rejectnotes"
             id='rejectnotes'
             value={rejectformik.values.rejectnotes}
             onChange={rejectformik.handleChange}
              className="border rounded-lg border-gray-200 p-4 w-full"
              rows="6"
              placeholder="Enter your response here"
            ></textarea>
             <InputErrorMessage  error={
                                            rejectformik.touched.rejectnotes &&
                                            rejectformik.errors.rejectnotes
                                          } />
            <div className="my-2">
              <div className="flex items-center justify-end gap-5">
                <button
                  className="px-4 py-2 min-h-10  text-accent border border-accent rounded-lg min-w-28 capitalize"
                  onClick={() => {
                    setRequestDecline(false);
                    rejectformik.resetForm();
                  }}
                >
                  Back
                </button>
                <button
                  className="px-4 py-2 min-h-10 bg-accent text-white rounded-lg min-w-28 capitalize"
                 type='submit'
                >
                  {RejectLoader ? <EditProfileLoader /> : 'Submit'}
                </button>
              </div>
            </div>
          </div>
        </div>
        </form>
      ) : (
        <section className="p-8 flex flex-col gap-6">
          <div className="max-w-[230px] w-full">
            {Loader ? (
              <div
                className="size-[230px] border-[1.5px] border-[#e9e9e9] rounded-[42px]  bg-slate-200 object-cover animate-pulse 
              dark:bg-gray-700 items-center justify-center flex"
              >
                <FaImage size={60} className="text-white" />
              </div>
            ) : data?.profile?.coverPic ? (
              <img
                src={`${process.env.REACT_APP_API_URL}/${data?.profile?.coverPic}`}
                alt="Cover"
                className="size-[230px] border-[1.5px] border-[#e9e9e9] rounded-[42px]  bg-slate-200 object-cover"
              />
            ) : (
              <img
                src={Noprofile}
                alt="No Profile"
                className="size-[230px] border-[1.5px] border-[#e9e9e9] rounded-[42px]  bg-slate-200 "
              />
            )}
          </div>
          <div className="w-full space-y-10">
            <div className="block">
              <h2 className="headingText mb-3">Hospital Details</h2>
              <div className="grid grid-cols-2 gap-x-4 gap-y-6">
                <div className="singleField">
                  <p className="subHeadingText capitalize">hospital Name</p>
                  <p className="baseText capitalize text-coalBlack">
                    {data?.profile?.hospitalName}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    Representative Email
                  </p>
                  <p className="baseText break-all text-coalBlack">
                    {data?.profile?.userId?.email}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    phone number
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.refCountryCode} {data?.profile?.refPhoneNo}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    registration number
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.regNumber}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    Hospital Capacity
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.hospitalCapacity}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    Hospital specialization
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.speciality}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    Latitude
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.lat}
                  </p>
                </div>
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    Longitude
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    {data?.profile?.long}
                  </p>
                </div>

                {/* <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    Representative Phone Number
                  </p>
                  <p className="baseText capitalize line-clamp-1 text-coalBlack">
                    9656565665
                  </p>
                </div> */}
              </div>
            </div>
            <div className="singleField">
              <p className="subHeadingText capitalize line-clamp-1">
                Hospital Address
              </p>
              <p className="baseText capitalize line-clamp-3 text-coalBlack">
                {data?.profile?.address}
              </p>
            </div>
            <div className="block">
              <h2 className="headingText mb-2">Submitted Documents</h2>
              <div className="grid grid-cols-1 gap-4">
                <div className="singleField">
                  <p className="subHeadingText capitalize line-clamp-1">
                    hospital License
                  </p>
                  {data?.profile?.documents.length == 0 ? (
                    <p>No document Submitted.</p>
                  ) : null}
                  {data?.profile?.documents?.map((data) => {
                    return (
                      <div
                        className="rounded-2xl shadow-md p-4 px-8 flex items-center cursor-pointer"
                        onClick={() => saveAs(`${process.env.REACT_APP_API_URL}/${data.filePath}`, data.fileName)}
                      >
                        <div className="inline-flex gap-5 items-center">
                          <FaAddressCard className="text-2xl text-accent inline-block " />
                          <p className="baseText capitalize line-clamp-1 text-coalBlack border-l border-gray-400 ps-2">
                            {data.fileName ||
                              data.filePath.substring(
                                data.filePath.lastIndexOf('/') + 1
                              )}
                          </p>
                        </div>
                        <LuDownload className="text-2xl text-coalBlack ms-auto" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default ViewNewRequest;
