import React, { useEffect, useState } from 'react';
import CreditUpdateCard from '../../components/creditManagement/CreditUpdateCard';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';
import MainOddsPopup from '../../components/Modals/MainOddsPopup';
import { AiFillInfoCircle } from 'react-icons/ai';
import { useFormik } from 'formik';
import { IoAddCircle } from 'react-icons/io5';
import {
  creditUpdateValidation,
  payoutUpdateValidation,
} from '../../components/Validation';
import { InputErrorMessage } from '../../components/errorMessages';
import {
  getCreditBasePrice,
  getSpecialization,
  toUpdateSpecialization,
  updateCreditDetails,
  createSpecialization,
} from '../../apis';
import toast from 'react-hot-toast';
import ConfirmPopup from '../../components/confirmPopup/ConfirmPopup';
import * as yup from 'yup';
import AddSpecialization from './AddSpecilization';

function UpdateCredits({ closeFunction }) {
  const [creditTab, setCreditTab] = useState(1);
  const [creditDetails, setCreditDetails] = useState(null);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [delConfirmPopup, setDelConfirmPopup] = useState(false);
  const [notDelPopup, setNotDelPopup] = useState(false);
  const [specializationData, setSpecializationData] = useState(null);
  const [splPayload, setSplPayload] = useState(null);
  const [splDelPayload, setSplDelPayload] = useState(null);
  const [reload, setReload] = useState(false);
  const validateForm = async (data) => {
    try {
      await creditUpdateValidation.validate(data, { abortEarly: false });
      console.log('Form is valid');
      setConfirmPopup(!confirmPopup);
    } catch (err) {
      console.log('Form is invalid');
      formik.setFieldTouched('virtualCreditUpdate', true);
      formik.setFieldTouched('inPersonCreditUpdate', true);
    }
  };
  const handleReload = () => {
    setReload(!reload);
  };
  const handleConfirmPopup = async () => {
    const value = {
      inPersonCreditUpdate: formik.values.virtualCreditUpdate,
      virtualCreditUpdate: formik.values.inPersonCreditUpdate,
    };
    await validateForm(value);
  };
  const handleNotDelPopup = () => {
    setNotDelPopup(!notDelPopup);
  };
  const handleDelConfirmPopup = async (payload) => {
    setSplDelPayload(payload);
    setDelConfirmPopup(!delConfirmPopup);
  };
  const handleConfirmPopupSpl = async (payload) => {
    console.log(payload);
    setSplPayload(payload);
    try {
      setConfirmPopup(!confirmPopup);
    } catch (err) {
      console.log(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      inPersonCreditUpdate: creditDetails?.InpersonConsultationCost || 0,
      virtualCreditUpdate: creditDetails?.VirtualConsultationCost || 0,
    },
    validationSchema: creditUpdateValidation,
    enableReinitialize: true,
    onSubmit: async (values) => {
      console.log(values);
      const { inPersonCreditUpdate, virtualCreditUpdate } = values;
      console.log(inPersonCreditUpdate);
      const payload = {
        Id: creditDetails?._id,
        InpersonConsultationCost: inPersonCreditUpdate,
        VirtualConsultationCost: virtualCreditUpdate,
      };
      await toUpdateCreditDetails(payload);
    },
  });

  const formik2 = useFormik({
    initialValues: { inPersonPayoutUpdate: 4688, virtualPayoutUpdate: 2563 },
    validationSchema: payoutUpdateValidation,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const initialValues = specializationData?.reduce((acc, item) => {
    acc[item.name] = item.Amount; // Initialize with existing amount
    return acc;
  }, {});

  const validationSchema = yup.object({
    specializationAmount: yup.object().shape(
      specializationData?.reduce((acc, item) => {
        acc[item.name] = yup
          .number()
          .typeError('Must be a number')
          .required('Amount is required')
          .positive('Must be a positive number')
          .integer('Must be an integer');
        return acc;
      }, {})
    ),
  });

  const formik3 = useFormik({
    initialValues: {
      specializationAmount: initialValues,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      console.log(
        'Updated Specialization Amounts:',
        values.specializationAmount
      );
    },
  });

  const virtualUpdateBlock = (
    <>
      <div className="shadow-blue_dropshadow rounded-3xl subHeadingText !font-semibold text-navyBlue p-4">
        <div className="grid grid-cols-2 text-center">
          <p className="border-r-2 border-gray-400">1 Visit</p>
          <div className="relative text-left">
            <input
              type="text"
              className="text-center max-w-[88%] px-1"
              value={formik.values.virtualCreditUpdate}
              onChange={formik.handleChange}
              placeholder={'Enter amount'}
              name="virtualCreditUpdate"
            />
            <span className="absolute top-[40%] -translate-y-1/2 right-2">
              ₹
            </span>
          </div>
        </div>
      </div>
      <p className="col-span-2 mt-2">
        <InputErrorMessage
          error={
            formik.touched.virtualCreditUpdate &&
            formik.errors.virtualCreditUpdate
          }
        />
      </p>
    </>
  );
  const inPersonUpdateBlock = (
    <>
      <div className="shadow-blue_dropshadow rounded-3xl subHeadingText !font-semibold text-navyBlue p-4">
        <div className="grid grid-cols-2 text-center">
          <p className="border-r-2 border-gray-400">1 Visit</p>
          <div className="relative text-left">
            <input
              type="text"
              className="text-center max-w-[88%] px-1"
              name="inPersonCreditUpdate"
              value={formik.values.inPersonCreditUpdate}
              onChange={formik.handleChange}
              placeholder={'Enter amount'}
            />
            <span className="absolute top-[40%] -translate-y-1/2 right-2">
              ₹
            </span>
          </div>
        </div>
      </div>
      <p className="col-span-2 mt-2">
        <InputErrorMessage
          error={
            formik.touched.inPersonCreditUpdate &&
            formik.errors.inPersonCreditUpdate
          }
        />
      </p>
    </>
  );

  const virtualPayoutUpdateBlock = (
    <>
      <div className="shadow-blue_dropshadow rounded-3xl subHeadingText !font-semibold text-navyBlue p-4">
        <div className="grid grid-cols-2 text-center">
          <p className="border-r-2 border-gray-400">1 Visit</p>
          <div className="relative text-left">
            <input
              type="text"
              className="text-center max-w-[80%] px-1"
              value={formik2.values.virtualPayoutUpdate}
              onChange={formik2.handleChange}
              name="virtualPayoutUpdate"
            />
            <span className="absolute top-[40%] -translate-y-1/2 right-2">
              ₹
            </span>
          </div>
        </div>
      </div>
      <p className="col-span-2 mt-2">
        <InputErrorMessage
          error={
            formik2.touched.virtualPayoutUpdate &&
            formik2.errors.virtualPayoutUpdate
          }
        />
      </p>
    </>
  );
  const inPersonPayoutUpdateBlock = (
    <>
      <div className="shadow-blue_dropshadow rounded-3xl subHeadingText !font-semibold text-navyBlue p-4">
        <div className="grid grid-cols-2 text-center">
          <p className="border-r-2 border-gray-400">1 Visit</p>
          <div className="relative text-left">
            <input
              type="text"
              className="text-center max-w-[80%] px-1"
              value={formik2.values.inPersonPayoutUpdate}
              onChange={formik2.handleChange}
              name="inPersonPayoutUpdate"
            />
            <span className="absolute top-[40%] -translate-y-1/2 right-2">
              ₹
            </span>
          </div>
        </div>
      </div>
      <p className="col-span-2 mt-2">
        <InputErrorMessage
          error={
            formik2.touched.inPersonPayoutUpdate &&
            formik2.errors.inPersonPayoutUpdate
          }
        />
      </p>
    </>
  );
  const toGetCreditBasePrice = async () => {
    try {
      const res = await getCreditBasePrice();
      if (res.status === 200) {
        setCreditDetails(res?.data?.credit[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const toUpdateCreditDetails = async (payload) => {
    try {
      const res = await updateCreditDetails(payload);
      if (res.status === 200) {
        console.log(res);
        toast.success('Amount Updated Successfully');
        setConfirmPopup(!confirmPopup);
        closeFunction();
      }
    } catch (error) {
      console.log(error);
      setConfirmPopup(!confirmPopup);
      toast.error(error.statusText || 'Something went wrong');
    } finally {
      setConfirmPopup(!confirmPopup);
      closeFunction();
    }
  };
  const updateSpecialization = async () => {
    try {
      const res = await toUpdateSpecialization(splPayload);
      if (res.status === 200) {
        console.log(res);
        toast.success('Amount Updated Successfully');
        setConfirmPopup(!confirmPopup);
        setReload(!reload);
      }
    } catch (error) {
      console.log(error);
      setConfirmPopup(!confirmPopup);
      toast.error(error.statusText || 'Something went wrong');
    } finally {
      setConfirmPopup(!confirmPopup);
      setReload(!reload);
    }
  };
  const deleteSpecialization = async () => {
    try {
      const res = await toUpdateSpecialization(splDelPayload);
      if (res.status === 200) {
        toast.success('Specialization deleted Successfully');
        setDelConfirmPopup(!delConfirmPopup);
        setReload(!reload);
      }
    } catch (error) {
      console.log(error);
      setDelConfirmPopup(!delConfirmPopup);
      toast.error(error.statusText || 'Something went wrong');
    } finally {
      setDelConfirmPopup(!delConfirmPopup);
      setReload(!reload);
    }
  };
  const toGetSpecialization = async () => {
    try {
      const res = await getSpecialization();
      if (res.status === 200) {
        console.log(res?.data?.result);
        setSpecializationData(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    toGetCreditBasePrice();
    toGetSpecialization();
  }, [reload]);
  return (
    <MainOddsPopup closeFunction={closeFunction}>
      <div className="w-full p-8 bg-bodybackground text-coalBlack min-w-[880px]">
        {/* Navigation  */}
        <div className="flex items-center">
          <div className="inline-flex gap-6 items-center">
            <SimpleButton
              title={'Consultation Cost'}
              buttonType={creditTab === 1 ? 'primary' : 'unfilled'}
              customClass={'rounded-full !px-5'}
              onClickEvent={() => {
                setCreditTab(1);
              }}
            />
            {/* <SimpleButton
              title={'Consultation Payout'}
              buttonType={creditTab === 2 ? 'primary' : 'unfilled'}
              customClass={'rounded-full !px-5'}
              onClickEvent={() => {
                setCreditTab(2);
              }}
            /> */}
            <SimpleButton
              title={'Specialization Cost'}
              buttonType={creditTab === 3 ? 'primary' : 'unfilled'}
              customClass={'rounded-full !px-5'}
              onClickEvent={() => {
                setCreditTab(3);
              }}
            />
          </div>
          <p
            className="subHeadingText text-navLink ms-auto cursor-pointer"
            onClick={closeFunction}
          >
            Discard
          </p>
        </div>
        <div className="flex items-center">
          <h1 className="headingText my-11">
            {creditTab === 1
              ? 'Consultation Cost'
              : creditTab === 3
                ? 'Specialization Cost'
                : ''}
          </h1>
          {creditTab === 3 && <AddSpecialization handleReload={handleReload} />}
        </div>
        {creditTab === 1 ? (
          <section className=" flex items-center gap-11">
            <CreditUpdateCard
              updateField={inPersonUpdateBlock}
              conType={' In-Person Consultation'}
              message={
                'This amount will be charged for hospitals to book In-Person doctor visit.'
              }
            />
            <CreditUpdateCard
              updateField={virtualUpdateBlock}
              conType={'Virtual Consultation'}
              message={
                'This amount will be charged for hospitals to book Virtual doctor visit.'
              }
            />
          </section>
        ) : creditTab === 2 ? (
          <section className=" flex items-center gap-11">
            <CreditUpdateCard
              updateField={inPersonPayoutUpdateBlock}
              conType={' In-Person Payout'}
              message={
                'The amount which will be paid to doctors for in-person consultation. '
              }
            />
            <CreditUpdateCard
              updateField={virtualPayoutUpdateBlock}
              conType={'Virtual Payout'}
              message={
                'The amount which will be paid to doctors for in-person consultation. '
              }
            />
          </section>
        ) : creditTab === 3 ? (
          <section className="w-full bg-white rounded-xl p-3 ">
            <p className="baseText  text-navLink text-start mb-6">
              <AiFillInfoCircle className="text-2xl text-navLink inline-block me-2" />{' '}
              This amount will be charged for hospitals to book consultations
              with these specializations.
            </p>
            <form onSubmit={formik3.handleSubmit}>
              <div className="flex flex-col gap-4 mb-3  ">
                {specializationData?.map((data, index) => (
                  <div className="flex items-center" key={data._id}>
                    <p className="subHeadingText text-coalBlack capitalize">
                      {data?.name}
                    </p>
                    <div className="relative flex gap-x-5 items-center ml-auto ">
                      <input
                        type="number"
                        name={`specializationAmount.${data?.name}`}
                        className="subHeadingText text-coalBlack border p-2 px-7 rounded-lg  max-w-56"
                        placeholder="Enter Amount"
                        onChange={formik3.handleChange}
                        value={
                          formik3.values.specializationAmount[data?.name] || ''
                        }
                      />
                      <span className="absolute left-2.5 text-base font-semibold top-1/2 -translate-y-1/2 ">
                        ₹
                      </span>
                      <SimpleButton
                        title={'Update'}
                        buttonType={'primary'}
                        customClass={
                          'rounded-2xl ms-auto  !min-w-[120px] !rounded-lg'
                        }
                        onClickEvent={() =>
                          handleConfirmPopupSpl({
                            Id: data?._id,
                            Amount:
                              formik3.values.specializationAmount[data?.name] ||
                              null,
                          })
                        }
                      />
                      {data?.isAssigned ? (
                        <SimpleButton
                          title={'Delete'}
                          customClass={
                            'rounded-2xl ms-auto bg-red-500/50 !min-w-[120px] text-white  !rounded-lg '
                          }
                          onClickEvent={handleNotDelPopup}
                        />
                      ) : (
                        <SimpleButton
                          title={'Delete'}
                          customClass={
                            'rounded-2xl ms-auto bg-red-500 !min-w-[120px] text-white  !rounded-lg'
                          }
                          onClickEvent={() =>
                            handleDelConfirmPopup({
                              Id: data?._id,
                              status: 'in-Active',
                            })
                          }
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </form>
          </section>
        ) : null}
        <div className="mt-11">
          {creditTab === 1 ? (
            <SimpleButton
              title={'Update'}
              buttonType={'primary'}
              customClass={'rounded-2xl ms-auto '}
              onClickEvent={handleConfirmPopup}
            />
          ) : null}
        </div>
      </div>
      {confirmPopup && (
        <ConfirmPopup
          message={'Are you sure you want to update the Amount?'}
          popupStatus={confirmPopup}
          onclickFunction={
            creditTab === 1 ? formik.handleSubmit : updateSpecialization
          }
          handelConfirmPopUp={handleConfirmPopup}
        />
      )}
      {delConfirmPopup && (
        <ConfirmPopup
          message={'Are you sure you want to delete the specialization?'}
          popupStatus={delConfirmPopup}
          onclickFunction={deleteSpecialization}
          handelConfirmPopUp={handleDelConfirmPopup}
        />
      )}
      {notDelPopup && (
        <section className="bg-black/25 fixed top-0 left-0 w-full h-screen z-50 flex justify-center items-center  lg:z-[9999]">
          <div
            className="absolute top-0 left-0 w-full h-full bg-inherit"
            onClick={handleNotDelPopup}
          ></div>
          <div className=" relative z-20 m-auto overflow-y-auto scrollbar   max-h-screen  lg:w-auto lg:max-w-4xl xl:max-w-5xl  lg:max-h-[96vh]  rounded-xl overscroll-contain">
            <div className="p-8 bg-white">
              <p className='baseText mb-4'>There are currently doctors registered with this specialization</p>
              <SimpleButton
                onClickEvent={handleNotDelPopup}
                title={'Close'}
                buttonType={'primary'}
                customClass={'rounded-md mx-auto '}
              />
            </div>
          </div>
        </section>
      )}
    </MainOddsPopup>
  );
}

export default UpdateCredits;
